import firebase from 'firebase/app';
import 'firebase/firestore';

var firebaseConfig = {
    apiKey: "AIzaSyAjEc36WWK9258bpGJGqfb_Pa7KcE7Mo6o",
    authDomain: "mdisopen.firebaseapp.com",
    databaseURL: "https://mdisopen.firebaseio.com",
    projectId: "mdisopen",
    storageBucket: "mdisopen.appspot.com",
    messagingSenderId: "141686275866",
    appId: "1:141686275866:web:d6e3223ec58b72dfb836a3",
    measurementId: "G-WKN45Z68X1"
  };

firebase.initializeApp(firebaseConfig);

export default firebase;