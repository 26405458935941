import React from 'react'

function Footer() {
    return (
        <div className='bg-black text-white'>
            <footer className='container p-5'>
                <div className='row'>
                    <div className='col-sm-auto mb-3 text-center d-sm-none mb-5'>
                        <a className='btn btn-social footer-button ' href='https://www.facebook.com/mdisopen' target='_blank' rel='noopener noreferrer' >
                            <span className='fa fa-facebook-f' />
                        </a>
                        <a className='btn btn-social ml-2 footer-button' href='https://www.instagram.com/mdisopen' target='_blank' rel='noopener noreferrer' >
                            <span className='fa fa-instagram' />
                        </a>
                        <a className='btn btn-social ml-2 footer-button' href='https://www.linkedin.com/showcase/mdisopen' target='_blank' rel='noopener noreferrer'>
                            <span className='fa fa-linkedin' />
                        </a>
                    </div>
                    <div className='col mb-3 small text-center d-sm-none'>
                        © 2020, Maryland is Open. All rights reserved
                    </div>
                    <div className='col mb-3 d-none d-sm-block'>
                        © 2020, Maryland is Open. All rights reserved
                    </div>
                    <div className='col-sm-auto text-center d-none d-sm-block'>
                        <a className='btn btn-social footer-button' href='https://www.facebook.com/mdisopen' target='_blank' rel='noopener noreferrer'>
                            <span className='fa fa-facebook' />
                        </a>
                        <a className='btn btn-social ml-2 footer-button' href='https://www.instagram.com/mdisopen' target='_blank' rel='noopener noreferrer'>
                            <span className='fa fa-instagram' />
                        </a>
                        <a className='btn btn-social ml-2 footer-button' href='https://www.linkedin.com/showcase/mdisopen' target='_blank' rel='noopener noreferrer'>
                            <span className='fa fa-linkedin' />
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer