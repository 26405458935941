import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Header from '../components/header'
import Footer from '../components/footer'

class AddBusiness extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Header />
                <div className='add-business-cover'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-8 offset-lg-2'>
                                <div className='card'>
                                    <div className='card-body'>
                                    <iframe src='https://docs.google.com/forms/d/e/1FAIpQLSf8umwNI91ejlD5MxNv09Wt7ISgiF2Z29p2EC-19rk-4Ww5gQ/viewform?embedded=true' width='640' height='3687' frameborder='0' marginheight='0' marginwidth='0' title='addBusiness'>Loading…</iframe>
                                    </div>
                                    <div className='text-center mb-3'>
                                        <Link to='/'> <div className='btn btn-danger m-auto '>Cancel</div></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default AddBusiness