import React, { Component } from 'react'
import Header from '../components/header'
import Footer from '../components/footer'

class BecomeBusiness extends Component {
    render() {
        return (
            <div>
                <Header />
                <div className='become-sponsor-cover'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-8 offset-lg-2'>
                                <div className='card'>
                                    <div className='card-body'>
                                    <iframe src='https://docs.google.com/forms/d/e/1FAIpQLSfS5jXITadvW1SUSL2XkxpM0pG-2wGo8-XjQ6x6L4mRoLRBsg/viewform?embedded=true' width='640' height='999' frameborder='0' marginheight='0' marginwidth='0'  title='sponser-form'>Loading…</iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        ) 
    }
}

export default BecomeBusiness