import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import axios from 'axios'
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import Firebase from '../../Firebase'
import { google_api_key } from '../../config'

import 'react-toastify/dist/ReactToastify.css';

import { collection_places } from '../../config'

import Spinner from '../../app/components/spinner'
import Header1 from '../../app/components/header'
import Footer from '../../app/components/footer'
import { isValidWebsiteUrl } from '../components/Utils'

const db = Firebase.firestore();
const shortid = require('shortid');
toast.configure()

class placeAddEdit extends Component {
    constructor(props) {
        super(props)
        this.validator = new SimpleReactValidator();
        this.state = {
            options: [],
            address: '',
            placeId: '',
            center: { lat: 38.27302299, lng: -75.6119152 },
            categoryId: 1,
            loading: false,
            categories: [],
            businessName: '',
            businessDescription: '',
            websiteLink: '',
            phone: '',
            dietary: '',
            outdoor_dining: false,
            delivery: false,
            carryout: false,
            locallyOwned: false,
            businessHours: {},
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
            sunday: '',
            fax: '',
            email: '',
            rangeKey: '',
            referenceId: '',
            type: '',
            busuinessNameLoading: false,
            websiteUrlClass: true,
            precautions: ''
        }
    }


    componentDidMount() {
        window.scrollTo(0, 0)
        let token = localStorage.getItem('loginToken');
        if (!token) {
            this.props.history.push('/admin')
        }
        if (this.props.match.path.split('/')[3] !== 'create') {
            let place = this.props.place
            let businessHours = place.opening_hours
            this.setState({
                rangeKey: place.rangeKey,
                referenceId: place.referenceId,
                placeId: place.place_id,
                businessName: place.name,
                fax: place.fax,
                email: place.email,
                businessDescription: place.description,
                categoryId: place.category_id,
                address: place.address,
                websiteLink: place.website,
                phone: place.phone,
                dietary: place.dietary_types,
                delivery: place.delivery,
                carryout: place.carryout,
                locallyOwned: place.locallyOwned,
                outdoor_dining: place.outdoor_dinning,
                monday: businessHours[1],
                tuesday: businessHours[2],
                wednesday: businessHours[3],
                thursday: businessHours[4],
                friday: businessHours[5],
                saturday: businessHours[6],
                sunday: businessHours[7],
                loading: false,
                precautions: place.covidPrecautions,
                center: { lat: place.location.Oa, lng: place.location.Ba }
            })
        }
        else {
            this.setState({ placeId: shortid.generate() })
        }
    }

    handleChange = address => {
        this.setState({ address });
    };

    handleSelect = async value => {
        await this.setState({ busuinessNameLoading: true })
        const address = await geocodeByAddress(value);
        const center = await getLatLng(address[0]);
        await this.setState({ center, address: address[0]?.formatted_address, placeId: address[0]?.place_id });
        let headers = {
            'Access-Control-Allow-Origin': '*'
        }
        const proxyurl = 'https://cors-anywhere.herokuapp.com/';
        axios.get(proxyurl + `https://maps.googleapis.com/maps/api/place/details/json?place_id=${address[0]?.place_id}&fields=name,business_status,website,url,international_phone_number,type,formatted_phone_number&key=${google_api_key}`, headers)
            .then((response) => {
                this.setState({
                    busuinessNameLoading: false,
                    businessName: response.data.result && response.data.result.name,
                    phone: response.data.result && response.data.result.formatted_phone_number,
                    websiteLink: response.data.result && response.data.result.website,
                    websiteUrlClass: true
                })

            })
            .catch(error => {
                console.log(error);
                this.setState({
                    busuinessNameLoading: false
                });
            });
    };

    onChange = (e) => {
        if (e.target.name === 'websiteLink') {
            let websiteUrlClass = e.target.value.length === 0
                ? true
                : ((e.target.value.length > 0) && isValidWebsiteUrl(e.target.value))
                    ? true
                    : false

            this.setState({ websiteUrlClass })
        }
        this.setState({ [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value });
    }

    onSubmit = async event => {
        event.preventDefault();
        if (this.state.businessName && this.state.address && this.state.websiteUrlClass === true) {
            let websiteUrl = ''
            if (this.state.websiteLink === undefined) {
                websiteUrl = ''
            }
            else {
                websiteUrl = this.state.websiteLink
            }

            let BusinessHours = {
                '1': this.state.monday,
                '2': this.state.tuesday,
                '3': this.state.wednesday,
                '4': this.state.thursday,
                '5': this.state.friday,
                '6': this.state.saturday,
                '7': this.state.sunday
            }
            let business =
            {
                'name': this.state.businessName,
                'category': '',
                'category_id': parseInt(this.state.categoryId),
                'description': this.state.businessDescription,
                'type': 'arts and entertainement',
                'phone': this.state.phone || "",
                'website': websiteUrl || "",
                'fax': this.state.fax,
                'rangeKey': this.state.rangeKey,
                'email': this.state.email,
                'photos': [],
                'price_level': 2,
                'delivery_types': this.state.options,
                'dietary_types': this.state.dietary,
                'location': new Firebase.firestore.GeoPoint(this.state.center.lat, this.state.center.lng),
                'opening_hours': BusinessHours,
                'address': this.state.address,
                'place_id': this.state.placeId,
                'delivery': this.state.delivery,
                'outdoor_dinning': this.state.outdoor_dining,
                'carryout': this.state.carryout,
                'locallyOwned': this.state.locallyOwned,
                'covidPrecautions': this.state.precautions || ''
            }

            db.collection(collection_places).doc(this.state.placeId).set(business).then(response => {
                this.setState({ loading: false })
                this.props.match.path.split('/')[3] === 'create'
                    ? toast.info(`${business.name} has been added successfully`)
                    : toast.info(`${business.name} has been updated successfully`)
                this.props.history.push('/admin/places')
            })
                .catch(error => {
                    console.log(error);
                    this.setState({ loading: false }, () => {
                        toast.error('Oops! There was an error trying to process your request. Please try again or contact support@eventhub.app');
                    });
                });
        } else {
            window.scrollTo(0, 350)
            toast.error('Please fill all the mandatory fields to add the business');
            event.preventDefault();
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    onMarkerDragEnd = (coord) => {
        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();
        this.setState({ center: { lat: lat, lng: lng } });
    }

    render() {
        return (
            <div>
                <Header1 />
                <div className='add-business-cover'>
                    <div className=''>
                        <div className='container h-100 my-auto'>
                            <div className='row align-items-center justify-content-center no-gutters h-100'>
                                <div className='col-12 col-md-10 col-lg-10'>
                                    <div className='card pt-3 mt-5'>
                                        <div className='card-body'>
                                            <div>
                                                <header className=' pt-9 pb-11'>
                                                    <div className='container-md'>
                                                        <div className='row align-items-center'>
                                                            <div className='col'>
                                                                <h1 className='font-weight-bold mb-2 text-center'>
                                                                    {this.props.match.path.split('/')[3] !== 'create' ? 'Edit Business' : 'Add My Business'}
                                                                </h1>
                                                                <p className='font-size-lg text-white-75 mb-0'>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </header>
                                            </div>
                                            <hr />
                                            <div className='pb-8 pb-md-11 mt-md-n6'>
                                                <Spinner display={this.state.loading}>
                                                    <div className='container-md'>
                                                        <div className='row'>
                                                            <div className='col-12 col-md-12'>
                                                                <div className='card-header bg-white border-bottom-0'>
                                                                    <h5 className='mb-0 font-weight-bold pt-2'>Business Location<span className='text-danger'>*</span></h5>
                                                                </div>
                                                                <div className='card-body pt-0'>
                                                                    <div className='list-group list-group-flush'>
                                                                        <div className='list-group-item'>
                                                                            <div className='row align-items-center'>
                                                                                <PlacesAutocomplete value={this.state.address} onChange={this.handleChange} onSelect={this.handleSelect}>
                                                                                    {
                                                                                        ({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                                                                            <div className='search-input-container col-12'>
                                                                                                <input {...getInputProps({ placeholder: 'e.g. 123 Main Street, Baltimore MD 21228', className: 'form-control business-form' })} value={this.state.address} />
                                                                                                <div className='small text-danger font-weight-bold'>{this.validator.message('address', this.state.address, 'required')}</div>
                                                                                                <div className='autocomplete-container'>
                                                                                                    {
                                                                                                        suggestions.map(suggestion => {
                                                                                                            const className = suggestion.active ? 'suggestion-item suggestion-item--active' : 'suggestion-item';
                                                                                                            return (
                                                                                                                <div {...getSuggestionItemProps(suggestion, { className })}>
                                                                                                                    <span>{suggestion.description}</span>
                                                                                                                </div>
                                                                                                            );
                                                                                                        })
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </PlacesAutocomplete>
                                                                            </div>
                                                                        </div>
                                                                        <div className='list-group-item' style={{ minHeight: 270 }}>
                                                                            <Map google={this.props.google} zoom={18}
                                                                                center={this.state.center}
                                                                                initialCenter={this.state.center}
                                                                            >
                                                                                <Marker
                                                                                    draggable={true}
                                                                                    onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)}
                                                                                    label={this.state.businessName}
                                                                                    position={{ lat: this.state.center.lat, lng: this.state.center.lng }} />
                                                                            </Map>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='card-body'>
                                                                    <form>
                                                                        <div className='row'>
                                                                            <div className='col-12 col-md-6'>
                                                                                <div className='form-group'>
                                                                                    <h5 className='mb-0 font-weight-bold py-2'>Business Name<span className='text-danger'>*</span></h5>
                                                                                    <Spinner display={this.state.busuinessNameLoading}>
                                                                                        <input className='form-control business-form' name='businessName' type='text' placeholder='e.g. Business name' value={this.state.businessName} onChange={this.onChange} />
                                                                                    </Spinner>
                                                                                    <div className='small text-danger font-weight-bold'>{this.validator.message('businessName', this.state.businessName, 'required')}</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-12 col-md-6'>
                                                                                <div className='form-group'>
                                                                                    <label className='mb-0 font-weight-bold py-2 h5'>Website Link</label>
                                                                                    <span className='text-muted' style={{ fontSize: 10 }}> (URL must contain https or http)</span>
                                                                                    <Spinner display={this.state.busuinessNameLoading}>
                                                                                        <input className='form-control business-form' placeholder='e.g. https://www.address.com' value={this.state.websiteLink} name='websiteLink' onChange={this.onChange} />
                                                                                    </Spinner>
                                                                                    {this.state.websiteUrlClass ? "" : <div className='small text-danger font-weight-bold'>Please enter a valid website url</div>}

                                                                                </div>
                                                                            </div>
                                                                            <div className='col-12'>
                                                                                <div className='form-group'>
                                                                                    <h5 className='mb-0 font-weight-bold py-2'>Business Description</h5>
                                                                                    <textarea className='form-control business-form' rows='4' value={this.state.businessDescription} name='businessDescription' onChange={this.onChange}></textarea>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-12 col-md-6'>
                                                                                <div className='form-group'>
                                                                                    <h5 className='mb-0 font-weight-bold py-2'>Business Category<span className='text-danger'>*</span></h5>
                                                                                    <select id='categoryId' name='categoryId' className='custom-select business-form' value={this.state.categoryId} onChange={this.onChange}>
                                                                                        <option value='1'>Arts and Entertainment</option>
                                                                                        <option value='2'>Automotive</option>
                                                                                        <option value='3'>Beauty and Fitness</option>
                                                                                        <option value='4'>Books and Literature</option>
                                                                                        <option value='5'>Business Services</option>
                                                                                        <option value='6'>Computer and Electronics</option>
                                                                                        <option value='7'>Education</option>
                                                                                        <option value='15'>Finance and Banking</option>
                                                                                        <option value='8'>Food and Drinks</option>
                                                                                        <option value='9'>Healthcare</option>
                                                                                        <option value='10'>Hobbies and Leisure</option>
                                                                                        <option value='11'>Home and Garden</option>
                                                                                        <option value='14'>Hotels and Lodging</option>
                                                                                        <option value='12'>Pets and Animals</option>
                                                                                        <option value='13'>Shopping</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-12 col-md-6'>
                                                                                <div className='form-group'>
                                                                                    <h5 className='mb-0 font-weight-bold py-2'>Phone</h5>
                                                                                    <Spinner display={this.state.busuinessNameLoading}>
                                                                                        <input className='form-control business-form' maxlength='10' type='text' placeholder='e.g. +1-42-8772-09' value={this.state.phone} name='phone' onChange={this.onChange} />
                                                                                    </Spinner>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-12 col-md-6'>
                                                                                <div className='form-group'>
                                                                                    <h5 className='mb-0 font-weight-bold py-2'>Dietary</h5>
                                                                                    <input className='form-control business-form' type='text' placeholder='e.g. Vegan' value={this.state.dietary} name='dietary' onChange={this.onChange} />
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-12 col-md-6'>
                                                                                <div className='form-group'>
                                                                                    <h5 className='mb-0 font-weight-bold py-2'>Fax</h5>
                                                                                    <input className='form-control business-form' type='text' placeholder='e.g. 65377289' value={this.state.fax} name='fax' onChange={this.onChange} />
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-12 col-md-6'>
                                                                                <div className='form-group'>
                                                                                    <h5 className='mb-0 font-weight-bold py-2'>Email</h5>
                                                                                    <input className='form-control business-form' type='email' placeholder='e.g. business@gmail.com' value={this.state.email} name='email' onChange={this.onChange} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                                <div className='card-header bg-white  border-bottom-0'>
                                                                    <h5 className='mb-0 font-weight-bold py-2'>Hours of Operation</h5>
                                                                    <span className='text-muted small'>Provide timeframe business is open, or simply say, Closed</span>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col'>
                                                                        <div className='form-group'>
                                                                            <h6 className='mb-0 font-weight-bold py-2'>Monday</h6>
                                                                            <input className='form-control business-form form-control-sm' type='text' placeholder='' name='monday' value={this.state.monday} onChange={this.onChange} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col'>
                                                                        <div className='form-group'>
                                                                            <h6 className='mb-0 font-weight-bold py-2'>Tuesday</h6>
                                                                            <input className='form-control business-form form-control-sm' type='text' placeholder='' name='tuesday' value={this.state.tuesday} onChange={this.onChange} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col'>
                                                                        <div className='form-group'>
                                                                            <h6 className='mb-0 font-weight-bold py-2'>Wednesday</h6>
                                                                            <input className='form-control business-form form-control-sm' type='text' placeholder='' name='wednesday' value={this.state.wednesday} onChange={this.onChange} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col'>
                                                                        <div className='form-group'>
                                                                            <h6 className='mb-0 font-weight-bold py-2'>Thursday</h6>
                                                                            <input className='form-control business-form form-control-sm' type='text' placeholder='' name='thursday' value={this.state.thursday} onChange={this.onChange} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col'>
                                                                        <div className='form-group'>
                                                                            <h6 className='mb-0 font-weight-bold py-2'>Friday</h6>
                                                                            <input className='form-control business-form form-control-sm' type='text' placeholder='' name='friday' value={this.state.friday} onChange={this.onChange} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col'>
                                                                        <div className='form-group'>
                                                                            <h6 className='mb-0 font-weight-bold py-2'>Saturday</h6>
                                                                            <input className='form-control business-form form-control-sm' type='text' placeholder='' name='saturday' value={this.state.saturday} onChange={this.onChange} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col'>
                                                                        <div className='form-group'>
                                                                            <h6 className='mb-0 font-weight-bold py-2'>Sunday</h6>
                                                                            <input className='form-control business-form form-control-sm' type='text' placeholder='' name='sunday' value={this.state.sunday} onChange={this.onChange} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='card-header bg-white  border-bottom-0'>
                                                                    <h5 className='mb-0 font-weight-bold py-2'>  <i class="fa fa-exclamation-triangle text-danger"></i>&nbsp;COVID-19 Safety Precautions</h5>
                                                                    <span className='text-muted small'>255 Characters limit</span>
                                                                </div>
                                                                <div className='col'>
                                                                    <div className='form-group'>
                                                                        <textarea className='form-control business-form form-control-sm' maxlength="255" rows='4' type='text' placeholder='' name='precautions' value={this.state.precautions} onChange={this.onChange} />
                                                                    </div>
                                                                </div>
                                                                <div className='card-header bg-white border-bottom-0'>
                                                                    <h5 className='mb-0 font-weight-bold py-2'> Additional Information</h5>
                                                                </div>
                                                                <div className='card-body pt-0'>
                                                                    <div className='list-group list-group-flush '>
                                                                        <div className='list-group-item border-0'>
                                                                            <div className='row align-items-center'>
                                                                                <div className='col'>
                                                                                    <p className='mb-0 font-weight-bold'>Delivery Available</p>
                                                                                    <small className='text-gray-700'>
                                                                                        Does the Business gives delivery for the products?
                                                                                      </small>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='custom-control custom-switch'>
                                                                                        <input type='checkbox' className='custom-control-input' id='delivery' checked={this.state.delivery} name='delivery' value='delivery' onChange={this.onChange} />
                                                                                        <label className='custom-control-label' htmlFor='delivery'></label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='list-group-item border-0'>
                                                                            <div className='row align-items-center'>
                                                                                <div className='col'>
                                                                                    <p className='mb-0 font-weight-bold'>Carryout / Curbside</p>
                                                                                    <small className='text-gray-700'>
                                                                                        Is Carryout or Curbside available at your business?
                                                                                        </small>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='custom-control custom-switch'>
                                                                                        <input type='checkbox' className='custom-control-input' id='carryout' checked={this.state.carryout} name='carryout' value='carryout' onChange={this.onChange} />
                                                                                        <label className='custom-control-label' htmlFor='carryout'></label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='list-group-item border-0'>
                                                                            <div className='row align-items-center'>
                                                                                <div className='col'>
                                                                                    <p className='mb-0 font-weight-bold'>Locally Owned</p>
                                                                                    <small className='text-gray-700'>
                                                                                        Is this business locally owned?
                                                                                        </small>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='custom-control custom-switch'>
                                                                                        <input type='checkbox' className='custom-control-input' id='locallyOwned' checked={this.state.locallyOwned} name='locallyOwned' value='locallyOwned' onChange={this.onChange} />
                                                                                        <label className='custom-control-label' htmlFor='locallyOwned'></label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='list-group-item border-0'>
                                                                            <div className='row align-items-center'>
                                                                                <div className='col'>
                                                                                    <p className='mb-0 font-weight-bold'>Outdoor Seating</p>
                                                                                    <small className='text-gray-700'>
                                                                                        Does this business provides outdoor seating?
                                                                                      </small>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='custom-control custom-switch'>
                                                                                        <input type='checkbox' className='custom-control-input' id='outdoor_dining' checked={this.state.outdoor_dining} name='outdoor_dining' value='outdoor_dining' onChange={this.onChange} />
                                                                                        <label className='custom-control-label' htmlFor='outdoor_dining'></label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Spinner>
                                            </div>
                                            <hr />
                                            <div className='row py-4 justify-content-center'>
                                                <div className='col-auto '>
                                                    <Link to='/admin/places'><button className='btn btn-lg btn-secondary mb-1 mr-2'>
                                                        Cancel</button>
                                                    </Link>
                                                    <button className='btn btn-lg btn-danger mb-1' type='button' onClick={this.onSubmit}>
                                                        Submit
                                                </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStatetoProps = state => {
    return {
        place: state.place,
    }
};
const Connect = connect(mapStatetoProps)(placeAddEdit)
export default (GoogleApiWrapper({ apiKey: google_api_key }))(Connect);

