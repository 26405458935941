
import React, { Component } from 'react'
import { FaUserAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'

class Header extends Component {

    logout = () => {
        localStorage.removeItem('loginToken');
    }

    render() {
        return (
            <div>
                <div>
                    <nav className='navbar bg-mdio-red'>
                        <div className='container'>

                            <a className='navbar-brand' href='/admin/places'>
                                <img src='/img/maryland-is-open-logo.png' className='navbar-brand-img' alt='Maryland is Open' style={{ maxHeight: '4rem' }} />
                            </a>
                            <ul className='nav'>
                                <li className='nav-item dropdown'>
                                    <p className='nav-link dropdown-toggle' id='navbarDropdownMenuLink-333' data-toggle='dropdown'
                                        aria-haspopup='true' aria-expanded='false'>
                                        <FaUserAlt />
                                    </p>
                                    <div className='dropdown-menu dropdown-menu-right dropdown-default'
                                        aria-labelledby='navbarDropdownMenuLink-333'>
                                        <Link to='/admin' className='dropdown-item' onClick={this.logout}>Logout</Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>

            </div>
        )
    }
}

export default Header
