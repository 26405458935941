import React from 'react'
import { Link } from 'react-router-dom'

function Features() {
    return (
        <div className='bg-mdio-2 py-5'>
            <div className='container'>
                <div className='card-deck text-center'>
                    <div className='card py-3'>
                        <div className='card-body'>
                            <img src='/img/icon-add-business.png' alt='Add Business' className='p-3' />
                            <div className='card-title'>
                                <h2 className='feature_heading'>Add My Business</h2>
                                <p>Maryland is open for business. Get your business listed today!</p>
                            </div>
                            <Link to='/add-business' className='btn btn-mdio-red feature_card_button'>Add My Business</Link>
                        </div>
                    </div>
                    <div className='card py-3'>
                        <div className='card-body'>
                            <img src='/img/icon-sponsor.png' alt='Add Business' className='p-3' />
                            <div className='card-title'>
                                <h2 className='feature_heading'>Become A Sponsor</h2>
                                <p>Come together and help us breathe life into Maryland businesses!</p>
                            </div>
                            <Link to='/become-sponsor' className='btn btn-mdio-red'>Become a Sponsor</Link>
                        </div>
                    </div>
                    <div className="w-100 d-lg-none d-md-none d-sm-block d-xs-block"></div>
                    <div className='card py-3 podcast___card'>
                        <div className='card-body'>
                            <img src='/img/podcast_icon.png' alt='Add Business' className='p-3' />
                            <h2 className='feature_heading'>The Podcast</h2>
                            <p className='feature_card_text'>A podcast dedicated to helping local businesses & community.</p>
                            <Link to='/podcast' className='btn btn-mdio-red podcast_button podcast_md'>Listen Now</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Features



