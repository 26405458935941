import React, { Component } from 'react'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { connect } from 'react-redux'
import moment from 'moment';
import ReadMoreAndLess from 'react-read-more-less';
import Firebase from '../../Firebase'

import Footer from '../components/footer'

import Features from '../components/features'
import Partners from '../components/partners'
import Sponsors from '../components/sponsors'
import Spinner from '../components/spinner'

import { collection_places, google_api_key } from '../../config'

import { GiPositionMarker } from 'react-icons/gi'

import BusinessHours from '../components/businessHours';
const db = Firebase.firestore();
class Places extends Component {
    constructor(props) {
        super(props)

        this.timeout = 0;

        this.state = {
            cheveronRight: <i className='fa fa-chevron-right text-dark'></i>,
            cheveronLeft: <i className='fa fa-chevron-down text-dark'></i>,
            keyword: '',
            categoryId: parseInt(this.props.match.params.category) || 0,
            typing: true,
            open: 'Currently Closed',
            delivery: false,
            carryout: false,

            center: {
                lat: this.props.match.params.lat || 39.2820678,
                lng: this.props.match.params.lng || -76.5916704
            },
            showCenter: true,

            address: this.props.match.params.address || '',

            categories: [],
            places: [],

            progressCategories: false,
            progressPlaces: false,

            activeMarker: {},
            showingInfoWindow: false,
            selectedPlace: {},

            northLat: parseInt(this.props.match.params.lat) + (0.02367),
            northLng: parseInt(this.props.match.params.lng) + (0.05764),
            southLat: parseInt(this.props.match.params.lat) - (0.02367),
            southLng: parseInt(this.props.match.params.lng) - (0.05764),
        }
    }

    componentDidMount = async () => {
        if (this.props.match.params.address === 'undefined') {
            this.setState({ address: '' })
        }
        else (
            this.setState({ address: this.props.match.params.address })
        )
    }

    getPlaces = async () => {
        let categoryId = parseInt(this.state.categoryId)
        let NORTH = new Firebase.firestore.GeoPoint(this.state.northLat, this.state.northLng);
        let SOUTH = new Firebase.firestore.GeoPoint(this.state.southLat, this.state.southLng);
        let EAST = new Firebase.firestore.GeoPoint(this.state.northLat, this.state.southLng)
        let WEST = new Firebase.firestore.GeoPoint(this.state.southLat, this.state.northLng)
        await this.setState({ loading: true });
        if (categoryId === 0) {
            this.setState({ loading: true });
            db.collection(collection_places).limit(50).where('location', '<', NORTH).where('location', '>', SOUTH).onSnapshot(async (dbPlaces) => {
                let places = [];
                dbPlaces.forEach(dbPlace => {
                    places.push(dbPlace.data());
                });
                places = places.filter(place => place.location.Ba > EAST.Ba && place.location.Ba < WEST.Ba)
                await this.setState({ places, loading: false });
            })
        }
        else {
            this.setState({ loading: true });
            db.collection(collection_places).limit(50).where('location', '<', NORTH).where('location', '>', SOUTH).onSnapshot(async (dbPlaces) => {
                let places = [];
                dbPlaces.forEach(dbPlace => {
                    places.push(dbPlace.data());
                });
                places = places.filter(place => place.category_id === categoryId && place.location.Ba > EAST.Ba && place.location.Ba < WEST.Ba)
                await this.setState({ places, loading: false });
            })
        }
    }

    handleChange = address => {
        this.setState({ address });
    };

    handleSelect = async value => {
        const address = await geocodeByAddress(value);
        const center = await getLatLng(address[0]);
        await this.setState({ center, address: value });
        await this.getPlaces();
    };

    onChange = async (event) => {
        await this.setState({ [event.target.name]: event.target.value });
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(async () => {
            await this.getPlaces()
        }, 300);
    }

    onCategoryChange = async (event) => {
        this.setState({ categoryId: parseInt(event.target.value) }, () => { this.getPlaces() })
    }

    directionMap = (location) => {
        if
            ((navigator.platform.indexOf('iPhone') !== -1) ||
            (navigator.platform.indexOf('iPad') !== -1))
            window.open(`maps://map.google.com/maps?daddr=${location.latitude},${location.longitude}&amp;ll=`);

        else
            window.open(`https://map.google.com/maps?daddr=${location.latitude},${location.longitude}&amp;ll=`);
    }

    onSelectPlace = async (place) => {
        const center = { lat: place.location.Oa, lng: place.location.Ba }
        const address = place.name;
        await this.setState({ center, address, selectedPlace: place, });

    }

    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props.place,
            activeMarker: marker,
            showingInfoWindow: true
        });
        this.infoWindow()
    }

    centerMoved = (mapProps, map) => {
        const center = { lat: map.center.lat(), lng: map.center.lng() }
        this.setState({

            northLat: map.getBounds().getNorthEast().lat(),
            northLng: map.getBounds().getNorthEast().lng(),
            southLat: map.getBounds().getSouthWest().lat(),
            southLng: map.getBounds().getSouthWest().lng(),
            center,
            address: ''
        }, () => { this.onMapChange() })

    }

    onMapClicked = () => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    }

    placeOpenHours = (place) => {
        this.props.dispatch({ type: 'PLACE', payload: place });
    }

    handleZoomChanged = (mapProps, map) => {
        let lat = map.center.lat();
        let lang = map.center.lng();
        this.setState({
            northLat: map.getBounds().getNorthEast().lat(),
            northLng: map.getBounds().getNorthEast().lng(),
            southLat: map.getBounds().getSouthWest().lat(),
            southLng: map.getBounds().getSouthWest().lng(),
            center: { lat: lat, lng: lang }
        }, () => { this.onMapChange() })
    };

    infoWindow = () => {
        let time = this.state.selectedPlace && this.state.selectedPlace.opening_hours
        const hours = time
        let currentDOW = moment().isoWeekday();
        if (currentDOW >= 1) {
            if (hours[currentDOW] && hours[currentDOW].toLowerCase() !== "") {
                this.setState({ open: hours[currentDOW] && hours[currentDOW].toLowerCase() })
            }
            else {
                this.setState({ open: 'Closed' })
            }
        }
        else {

            if (hours['7'] && hours['7'].toLowerCase() !== '') {
                this.setState({ open: hours['7'] && hours['7'].toLowerCase() })
            }
            else {
                this.setState({ open: 'Closed' })
            }
        }
    }

    doSearch = (event) => {
        if (event.target.value === '') {
            this.getPlaces()
        }
        else {
            this.setState({ keyword: event.target.value }, () => {
                let searchKeyword = this.state.keyword;
                let searchItem = "";
                searchItem = searchItem + searchKeyword;
                let places = this.state.places;
                let searchResults = places.filter(function (place) {
                    return (
                        (place.name.toLowerCase().trim().replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\]\\]/gi, '').search(searchItem.toLowerCase().trim().replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\]\\]/gi, '')) !== -1) ||
                        (place.description.toLowerCase().trim().replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\]\\]/gi, '').search(searchItem.toLowerCase().trim().replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\]\\]/gi, '')) !== -1)
                    );
                });
                this.setState({
                    places: searchResults
                });
            });
        }
    }

    onDeliveryChange = async (event) => {

        await this.setState({ [event.target.name]: event.target.checked });
        let NORTH = new Firebase.firestore.GeoPoint(this.state.northLat, this.state.northLng);
        let SOUTH = new Firebase.firestore.GeoPoint(this.state.southLat, this.state.southLng);
        let EAST = new Firebase.firestore.GeoPoint(this.state.northLat, this.state.southLng)
        let WEST = new Firebase.firestore.GeoPoint(this.state.southLat, this.state.northLng)
        let categoryId = parseInt(this.state.categoryId)
        this.setState({ loading: true });
        if (categoryId !== 0) {
            this.state.delivery === false && this.state.carryout === false ?
                this.getPlaces() : this.state.delivery === true && this.state.carryout === false ? db.collection(collection_places).limit(50).where('location', '<', NORTH)
                    .where('location', '>', SOUTH).onSnapshot(async (dbPlaces) => {
                        let places = [];
                        dbPlaces.forEach(dbPlace => {
                            places.push(dbPlace.data());
                        });
                        places = places.filter(place => place.delivery === true && place.category_id === categoryId && place.location.Ba > EAST.Ba && place.location.Ba < WEST.Ba)

                        await this.setState({ places, loading: false });
                    }) : this.state.delivery === true && this.state.carryout === true ? db.collection(collection_places).limit(50).where('location', '<', NORTH)
                        .where('location', '>', SOUTH).onSnapshot(async (dbPlaces) => {
                            let places = [];
                            dbPlaces.forEach(dbPlace => {
                                places.push(dbPlace.data());
                            });
                            places = places.filter(place => place.delivery === true && place.carryout === true && place.category_id === categoryId && place.location.Ba > EAST.Ba && place.location.Ba < WEST.Ba)
                            await this.setState({ places, loading: false });
                        }) : this.state.delivery === false && this.state.carryout === true ? db.collection(collection_places).limit(50).where('location', '<', NORTH)
                            .where('location', '>', SOUTH).where('category_id', '==', categoryId).onSnapshot(async (dbPlaces) => {
                                let places = [];
                                dbPlaces.forEach(dbPlace => {
                                    places.push(dbPlace.data());
                                });
                                places = places.filter(place => place.carryout === true && place.location.Ba > EAST.Ba && place.location.Ba < WEST.Ba)
                                await this.setState({ places, loading: false });
                            }) : this.getPlaces()
        }
        else {
            this.state.delivery === false && this.state.carryout === false ?
                this.getPlaces() : this.state.delivery === true && this.state.carryout === false ? db.collection(collection_places).limit(50).where('location', '<', NORTH).where('location', '>', SOUTH).onSnapshot(async (dbPlaces) => {
                    let places = [];
                    dbPlaces.forEach(dbPlace => {
                        places.push(dbPlace.data());
                    });
                    places = places.filter(place => place.delivery === true)
                    await this.setState({ places, loading: false });
                }) : this.state.delivery === true && this.state.carryout === true ? db.collection(collection_places).limit(50).where('location', '<', NORTH).where('location', '>', SOUTH).onSnapshot(async (dbPlaces) => {
                    let places = [];
                    dbPlaces.forEach(dbPlace => {
                        places.push(dbPlace.data());
                    });
                    places = places.filter(place => place.delivery === true && place.carryout === true)
                    await this.setState({ places, loading: false });
                }) : this.state.delivery === false && this.state.carryout === true ? db.collection(collection_places).limit(50).where('location', '<', NORTH).where('location', '>', SOUTH).onSnapshot(async (dbPlaces) => {
                    let places = [];
                    dbPlaces.forEach(dbPlace => {
                        places.push(dbPlace.data());
                    });
                    places = places.filter(place => place.carryout === true)
                    await this.setState({ places, loading: false });
                }) : this.getPlaces()
        }
    }

    onMapChange = () => {
        let NORTH = new Firebase.firestore.GeoPoint(this.state.northLat, this.state.northLng);
        let SOUTH = new Firebase.firestore.GeoPoint(this.state.southLat, this.state.southLng);
        let EAST = new Firebase.firestore.GeoPoint(this.state.northLat, this.state.southLng)
        let WEST = new Firebase.firestore.GeoPoint(this.state.southLat, this.state.northLng)
        let categoryId = parseInt(this.state.categoryId)
        this.setState({ loading: true });
        if (categoryId !== 0) {
            this.state.delivery === false && this.state.carryout === false ?
                this.getPlaces() : this.state.delivery === true && this.state.carryout === false ? db.collection(collection_places).limit(50).where('location', '<', NORTH)
                    .where('location', '>', SOUTH).onSnapshot((dbPlaces) => {
                        let places = [];
                        dbPlaces.forEach(dbPlace => {
                            places.push(dbPlace.data());
                        });
                        places = places.filter(place => place.delivery === true && place.category_id === categoryId && place.location.Ba > EAST.Ba && place.location.Ba < WEST.Ba)
                        this.setState({ places, loading: false });
                    }) : this.state.delivery === true && this.state.carryout === true ? db.collection(collection_places).limit(50).where('location', '<', NORTH)
                        .where('location', '>', SOUTH).onSnapshot((dbPlaces) => {
                            let places = [];
                            dbPlaces.forEach(dbPlace => {
                                places.push(dbPlace.data());
                            });
                            places = places.filter(place => place.delivery === true && place.carryout === true && place.category_id === categoryId && place.location.Ba > EAST.Ba && place.location.Ba < WEST.Ba)
                            this.setState({ places, loading: false });
                        }) : this.state.delivery === false && this.state.carryout === true ? db.collection(collection_places).limit(50).where('location', '<', NORTH)
                            .where('location', '>', SOUTH).where('category_id', '==', categoryId).onSnapshot(async (dbPlaces) => {
                                let places = [];
                                dbPlaces.forEach(dbPlace => {
                                    places.push(dbPlace.data());
                                });
                                places = places.filter(place => place.carryout === true && place.location.Ba > EAST.Ba && place.location.Ba < WEST.Ba)
                                this.setState({ places, loading: false });
                            }) : this.getPlaces()
        }
        else {
            this.state.delivery === false && this.state.carryout === false ?
                this.getPlaces() : this.state.delivery === true && this.state.carryout === false ? db.collection(collection_places).limit(50).where('location', '<', NORTH).where('location', '>', SOUTH).onSnapshot(async (dbPlaces) => {
                    let places = [];
                    dbPlaces.forEach(dbPlace => {
                        places.push(dbPlace.data());
                    });
                    places = places.filter(place => place.delivery === true)
                    this.setState({ places, loading: false });
                }) : this.state.delivery === true && this.state.carryout === true ? db.collection(collection_places).limit(50).where('location', '<', NORTH).where('location', '>', SOUTH).onSnapshot(async (dbPlaces) => {
                    let places = [];
                    dbPlaces.forEach(dbPlace => {
                        places.push(dbPlace.data());
                    });
                    places = places.filter(place => place.delivery === true && place.carryout === true)
                    this.setState({ places, loading: false });
                }) : this.state.delivery === false && this.state.carryout === true ? db.collection(collection_places).limit(50).where('location', '<', NORTH).where('location', '>', SOUTH).onSnapshot(async (dbPlaces) => {
                    let places = [];
                    dbPlaces.forEach(dbPlace => {
                        places.push(dbPlace.data());
                    });
                    places = places.filter(place => place.carryout === true)
                    this.setState({ places, loading: false });
                }) : this.getPlaces()
        }
    }

    onMapIdle = (mapProps, map) => {
        this.setState({
            northLat: map && map.getBounds().getNorthEast().lat(),
            northLng: map && map.getBounds().getNorthEast().lng(),
            southLat: map && map.getBounds().getSouthWest().lat(),
            southLng: map && map.getBounds().getSouthWest().lng(),
        }, () => { this.getPlaces() })
    }

    render() {

        const center = this.state.center;
        let selectTimePlace = ''
        if (this.props.openingTime) {
            selectTimePlace = this.props && this.props.openingTime
        }

        return (
            <div>
                <header className='app-header'>
                    <a href='/'>
                        <img src='/img/maryland-is-open-logo.png' className='place-header' alt='Maryland Is Open Logo' />
                    </a>
                    <div className='app-header-bg'></div>
                </header>
                <div className='app-container'>
                    <div className='places-container'>
                        <div className='card' >
                            <div className=''>
                                <Map google={this.props.google} ref={this.onMapLoad} onIdle={this.onMapIdle} mapTypeControl={false} streetViewControl={false} zoom={12} minZoom={10} maxZoom={18} center={center} initialCenter={center} onDragend={this.centerMoved} onZoomChanged={this.handleZoomChanged} onClick={this.onMapClicked} containerStyle={{ height: '35vh' }}>
                                    {
                                        this.state.places.map((place, index) => {
                                            return <Marker className='labels' key={index} id={index} position={{ lat: place.location.latitude, lng: place.location.longitude }} onClick={this.onMarkerClick} place={place} />
                                        })
                                    }
                                </Map></div>

                        </div>
                        <div className='card card-border shadow rounded-0 business-card' style={{ top: '-2px' }}>

                            <div className='card-body p-1'>
                                <Spinner display={this.state.progressCategories}>
                                    <form className='form-row align-items-center justify-content-center'>
                                        <div className='col-sm'>
                                            <label className='font-weight-bold small mb-0'>Show me</label>
                                            <select id='categoryId' name='categoryId' className='custom-select map-form' value={this.state.categoryId} onChange={this.onCategoryChange}>
                                                <option value='0'>All</option>
                                                <option value='1'>Arts and Entertainment</option>
                                                <option value='2'>Automotive</option>
                                                <option value='3'>Beauty and Fitness</option>
                                                <option value='4'>Books and Literature</option>
                                                <option value='5'>Business Services</option>
                                                <option value='6'>Computer and Electronics</option>
                                                <option value='7'>Education</option>
                                                <option value='15'>Finance and Banking</option>
                                                <option value='8'>Food and Drinks</option>
                                                <option value='9'>Healthcare</option>
                                                <option value='10'>Hobbies and Leisure</option>
                                                <option value='11'>Home and Garden</option>
                                                <option value='14'>Hotels and Lodging</option>
                                                <option value='12'>Pets and Animals</option>
                                                <option value='13'>Shopping</option>
                                            </select>
                                        </div>
                                        <div className='col-sm'>
                                            <label className='font-weight-bold small mb-0'>Near me</label>
                                            <PlacesAutocomplete value={this.state.address} onChange={this.handleChange} onSelect={this.handleSelect}>
                                                {
                                                    ({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                        <div className='search-input-container'>
                                                            <input {...getInputProps({ placeholder: 'e.g. 123 Main Street, Baltimore MD 21228', className: 'form-control map-form' })} value={this.state.address} />
                                                            <div className='autocomplete-container'>
                                                                {
                                                                    suggestions.map(suggestion => {
                                                                        const className = suggestion.active ? 'suggestion-item suggestion-item--active' : 'suggestion-item';
                                                                        return (
                                                                            <div {...getSuggestionItemProps(suggestion, { className })}>
                                                                                <span>{suggestion.description}</span>
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </PlacesAutocomplete>
                                        </div>
                                    </form>
                                    <input id='keyword' name='keyword' type='text' className='form-control map-form mt-2' placeholder='Keyword' onChange={this.doSearch}></input>
                                    <div className='mt-3'>
                                        <div className='form-check form-check-inline'>
                                            <input className='form-check-input' type='checkbox' id='delivery' name='delivery' value={this.state.delivery} onChange={this.onDeliveryChange} />
                                            <label className='form-check-label small font-weight-bold'>Delivery</label>
                                        </div>
                                        <div className='form-check form-check-inline ml-3'>
                                            <input className='form-check-input' type='checkbox' id='carryout' name='carryout' value={this.state.carryout} onChange={this.onDeliveryChange} />
                                            <label className='form-check-label small font-weight-bold'>Carryout / Curbside</label>
                                        </div>
                                    </div>
                                    <hr />
                                </Spinner>
                                <div className='places-list'>
                                    <Spinner display={this.state.progressPlaces}>
                                        {
                                            this.state.places.length !== 0 ?
                                                <div className='list-group list-group-flush'>
                                                    {
                                                        this.state.places && this.state.places.map((place, index) => {
                                                            return (
                                                                <div className='list-group-item d-flex align-items-center py-2 px-0 business-hover' key={index}>
                                                                    <div className='mr-auto'>
                                                                        <p className='mb-0 company-name'>{place.name}</p>
                                                                        <div className="small mb-1">
                                                                            <ReadMoreAndLess
                                                                                ref={this.ReadMore}
                                                                                className="read-more-content"
                                                                                charLimit={120}
                                                                                readMoreText={this.state.cheveronRight}
                                                                                readLessText={this.state.cheveronLeft}
                                                                            >
                                                                                {place.description}
                                                                            </ReadMoreAndLess>
                                                                        </div>
                                                                        <div className='small mb-2'>
                                                                            <span className='text-danger direction-marker'><GiPositionMarker /></span>
                                                                            <span className='direction-marker' onClick={() => { this.directionMap(place.location) }}>Get Directions</span>
                                                                        </div>
                                                                        {
                                                                            place.phone && place.website ?
                                                                                <div className="small">
                                                                                    <a className="text-dark font-weight-bold" href={`tel:${place.phone}`}>
                                                                                        {place?.phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")}
                                                                                    </a>
                                                                                    &nbsp;&nbsp;<span>|</span>&nbsp;&nbsp;
                                                                                     <a className="website-link " href={place.website} rel="noopener noreferrer" target="_blank">
                                                                                        Website
                                                                                     </a>
                                                                                </div> : place.website === "" ? <div className="small">
                                                                                    <a className="text-dark font-weight-bold" href={`tel:${place.phone}`}>
                                                                                        {place?.phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")}
                                                                                    </a></div> : place.phone === "" ? <div className="small"> <a className="website-link" href={place.website} rel="noopener noreferrer" target="_blank">
                                                                                        Website
                                                                                </a></div> : ""
                                                                        }
                                                                        <div className='d-inline-flex'>
                                                                            <BusinessHours hours={place && place.opening_hours} /><i className='fa fa-info-circle ml-1 text-danger' aria-hidden='true' data-toggle='modal' data-target='#exampleModalCenter' data-trigger='hover'
                                                                                onClick={() => { this.placeOpenHours(place) }}
                                                                                style={{ cursor: 'pointer' }}></i></div>
                                                                        {
                                                                            place.covidPrecautions ?
                                                                                <>
                                                                                    <p className="mb-0 covid-text">
                                                                                        <i class="fa fa-exclamation-triangle text-danger"></i>&nbsp;COVID-19 Safety Precautions
                                                                                </p>
                                                                                    <div className="small">
                                                                                        <ReadMoreAndLess
                                                                                            ref={this.ReadMore}
                                                                                            className="read-more-content"
                                                                                            charLimit={120}
                                                                                            readMoreText={this.state.cheveronRight}
                                                                                            readLessText={this.state.cheveronLeft}
                                                                                        >
                                                                                            {place.covidPrecautions}
                                                                                        </ReadMoreAndLess>
                                                                                    </div></> :
                                                                                <></>
                                                                        }
                                                                        <div className='row px-2 mr-0'>
                                                                            {
                                                                                place.delivery === true ?
                                                                                    <div className='px-1'>
                                                                                        <div className='media '>
                                                                                            <img className='align-self-center mr-1' width='38' height='62' src='/img/delivery-available.svg' alt='Generic' />
                                                                                            <div className='media-body pt-3 mt-1 detail_icons'>
                                                                                                <p className=' mb-0 font-weight-bold '>Delivery</p>
                                                                                                <p className=' mb-0 font-weight-bold'>Available</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    : ''
                                                                            }
                                                                            {
                                                                                place.carryout === true ?
                                                                                    <div className='px-2'>
                                                                                        <div className='media mt-2'>
                                                                                            <img className='align-self-center mr-1' width='34' height='40' src='/img/carryout-curbside.svg' alt='Generic placehol' />
                                                                                            <div className='media-body pt-2 mt-1 detail_icons'>
                                                                                                <p className=' mb-0 font-weight-bold ' > Carryout/</p>
                                                                                                <p className=' mb-0 font-weight-bold ' >Curbside</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div> : ''
                                                                            }
                                                                            {
                                                                                place.outdoor_dinning === true ?
                                                                                    <div className='px-2'>
                                                                                        <div className='media mt-1'>
                                                                                            <img className='align-self-center mr-1' width='36' height='52' src='/img/outdoor-seating.svg' alt='Generic placeh' />
                                                                                            <div className='media-body detail_icons mt-3' >
                                                                                                <p className=' mb-0 font-weight-bold ' > Outdoor</p>
                                                                                                <p className=' mb-0 font-weight-bold ' >Seating</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div> : ''
                                                                            }
                                                                            {
                                                                                place.locallyOwned ?
                                                                                    <div className='px-2'>
                                                                                        <div className='media mt-1' >
                                                                                            <img className='align-self-center mr-1 pb-1 ' width='40' height='60' src='/img/locally-owned.svg' alt='Generic placeholder ' />
                                                                                            <div className='media-body detail_icons mt-3' >
                                                                                                <p className=' mb-0 font-weight-bold'> Locally</p>
                                                                                                <p className=' mb-0 font-weight-bold'>Owned</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div> : ''
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div> : <div className='text-center'>No businesses found nearby.<span><br></br>Please search at a different location</span></div>
                                        }
                                    </Spinner>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-none d-md-block'>
                    </div>
                </div>
                <div className='feature-class'><Features /></div>
                <Sponsors />
                <Partners />
                <Footer />
                <div className='modal' id='exampleModalCenter' tabIndex='-1' role='dialog' aria-labelledby='exampleModalCenterTitle' aria-hidden='true'>
                    <div className='modal-dialog modal-dialog-centered' role='document'>
                        <div className='modal-content w-75'>
                            <div className='modal-header'>
                                <h5 className='modal-title' id='exampleModalLongTitle'>{this.props.place && this.props.place.name}</h5>
                                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                                    <span aria-hidden='true'>&times;</span>
                                </button>
                            </div>
                            <div className='modal-body'>
                                <ul className='list-group list-group-flush'>
                                    <li className='list-group-item'>Monday:<span className='float-right'>{selectTimePlace && selectTimePlace['1'].toLowerCase() ? selectTimePlace && selectTimePlace['1'].toLowerCase() : 'Closed'}</span></li>
                                    <li className='list-group-item'>Tuesday:<span className='float-right'>{selectTimePlace && selectTimePlace['2'].toLowerCase() ? selectTimePlace && selectTimePlace['2'].toLowerCase() : 'Closed'}</span></li>
                                    <li className='list-group-item'>Wednesday:<span className='float-right'>{selectTimePlace && selectTimePlace['3'].toLowerCase() ? selectTimePlace && selectTimePlace['3'].toLowerCase() : 'Closed'}</span></li>
                                    <li className='list-group-item'>Thursday:<span className='float-right'>{selectTimePlace && selectTimePlace['4'].toLowerCase() ? selectTimePlace && selectTimePlace['4'].toLowerCase() : 'Closed'}</span></li>
                                    <li className='list-group-item'>Friday:<span className='float-right'>{selectTimePlace && selectTimePlace['5'].toLowerCase() ? selectTimePlace && selectTimePlace['5'].toLowerCase() : 'Closed'}</span></li>
                                    <li className='list-group-item'>Saturday:<span className='float-right'>{selectTimePlace && selectTimePlace['6'].toLowerCase() ? selectTimePlace && selectTimePlace['6'].toLowerCase() : 'Closed'}</span></li>
                                    <li className='list-group-item'>Sunday:<span className='float-right'>{selectTimePlace && selectTimePlace['7'].toLowerCase() ? selectTimePlace && selectTimePlace['7'].toLowerCase() : 'Closed'}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStatetoProps = state => {
    return {
        place: state && state.place,
        openingTime: state.place && state.place.opening_hours
    }
};
const Connect = connect(mapStatetoProps)(Places)
export default (GoogleApiWrapper({ apiKey: google_api_key }))(Connect);
