import React from 'react';

class Spinner extends React.Component {
    render(props) {
        return <>
            <div className={this.props.display ? 'text-center' : 'd-none'}>
                <div className='row'>
                    <div className='col-lg-4 offset-lg-4'>
                        <div className='spinner-border text-danger' role='status' >
                            <span className='sr-only'>Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={this.props.display ? 'd-none' : ''}>
                {this.props.children}
            </div>
        </>;
    }
}

export default Spinner;