import React from 'react'

function partners() {
    return (
        <div>
            <div className='container pb-5 text-center'>
            <h3>Our Partners</h3>
            <div className='row p-5 align-items-center justify-content-center'>
                <div className='col-sm-4'>
                    <a href='https://mdtourism.org/' target='_blank' rel='noopener noreferrer'>
                        <img src='/img/partner-mdtourismcoalition.png' alt='sponsor' className='img-fluid my-3' />
                    </a>
                </div>
                <div className='col-sm-4'>
                    <a href='https://ocvisitor.com/ ' target='_blank' rel='noopener noreferrer'>
                        <img src='/img/partner-oceancity.png' alt='sponsor' className='img-fluid my-3' />
                    </a>
                </div>
            </div>
        </div>
        </div>
    )
}

export default partners
