import React, { Component } from 'react'
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import Firebase from '../../Firebase'
import Spinner from '../../app/components/spinner'
import 'react-toastify/dist/ReactToastify.css';
require('firebase/auth');

class Login extends Component {
    constructor(props) {
        super(props)
        this.validator = new SimpleReactValidator();
        this.state = {
            email: '',
            password: '',
        }
    }

    componentDidMount() {
        localStorage.removeItem('loginToken');
    }

    onEmailChange = (event) => {
        this.setState({ email: event.target.value, isValidEmail: event.target.value.length > 0 ? 'is-valid' : 'is-invalid' });
    }

    onPasswordChange = (event) => {
        this.setState({ password: event.target.value, isValidPassword: event.target.value.length > 0 ? 'is-valid' : 'is-invalid' });
    }

    onSubmit = async () => {
        if (this.state.email && this.state.password) {
            await this.setState({ loading: true })
            Firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
                .then(async (firebaseUser) => {
                    await this.setState({ loading: false })
                    localStorage.setItem('loginToken', firebaseUser.user.refreshToken);
                    this.props.history.push('/admin/places');

                }).catch(async (error) => {
                    await this.setState({ loading: false })
                    toast.error('Invalid Email / Password. Please try again.');
                });

        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        return (
            <>
                <div className='vh-100 bg-mdio-1 home-cover'>
                    <div className='container h-100 my-auto'>
                        <div className='row align-items-center justify-content-center no-gutters h-100'>
                            <div className='col-12 col-md-8 col-lg-6'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <h3 className='text-center'>Maryland is Open</h3>
                                        <h5 className='text-center font-weight-bold'>Admin Console</h5>
                                        <hr />
                                        <Spinner display={this.state.loading}>
                                            <div className='form-group'>
                                                <label htmlFor='email'>Email Address</label>
                                                <input type='email' className='form-control' id='email' placeholder='name@address.com' value={this.state.email} onChange={this.onEmailChange} />
                                                <div className='small text-danger'>{this.validator.message('email', this.state.email, 'required')}</div>
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='password'>Password </label>
                                                <input type='password' className='form-control' id='password' placeholder='Enter your password' value={this.state.password} onChange={this.onPasswordChange} />
                                                <div className='small text-danger'>{this.validator.message('password', this.state.password, 'required')}</div>
                                            </div>
                                            <button className='btn btn-block btn-mdio-red' onClick={() => { this.onSubmit() }}> Sign In </button>
                                        </Spinner>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Login