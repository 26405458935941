import React from 'react'
import moment from 'moment';

class BusinessHours extends React.Component {

    render(props) {
        let time = this.props && this.props.hours
        const hours = time
        let open =''
        var currentDOW = moment().isoWeekday();
        if (currentDOW >= 1) {
            if(hours[currentDOW] && hours[currentDOW].toLowerCase()!==""){
            open=hours[currentDOW] && hours[currentDOW].toLowerCase();
            }
            else{
                open='Closed'
            }
        }
        else {
            if(hours['7'] && hours['7'].toLowerCase()!==""){
                open=hours['7'] && hours['7'].toLowerCase();
                }
                else{
                    open='Closed'
                }
        }
        return (
            <>
                <div className='small text-capitalize'>{open} </div>
            </>

        );
    }
}

export default BusinessHours