import React, { Component } from 'react'
import Header from '../components/header';
import Footer from '../components/footer';

class podcast extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <div>
                    <Header />
                    <div className='add-business-cover'>
                        <div className='container h-100 my-auto'>
                            <div className='row align-items-center justify-content-center no-gutters h-100'>
                                <div className='col-12 col-md-10 col-lg-10'>
                                    <div className='card pt-3 mt-5'>
                                        <div className='card-body'>
                                            <div>
                                                <header className=' pt-9 pb-11 '>
                                                    <div className='container-md'>
                                                        <div className='row align-items-center'>
                                                            <div className='col'>
                                                                <h2 className='font-weight-bold mb-2 text-center'>Maryland is Open – Podcast</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </header>
                                            </div>
                                            <hr />
                                            <div className='container-md'>
                                                <div className='row'>
                                                    <div className='col-12 col-md-12 col-lg-12'>
                                                        <div className='row mb-4'>
                                                            <div className='col-sm-12 col-md-4 col-lg-4 col-xs-12 pr-0'>
                                                                <p>
                                                                    <img className='podcast__image' src='https://www.enradius.com/wp-content/uploads/2020/08/mdpodcastcover_v7-01-300x300.jpg' alt='podcast__image' />
                                                                </p>
                                                            </div>
                                                            <div className='col-12 col-md-8 col-lg-8'>
                                                                <p>
                                                                    The world has changed and this podcast is dedicated to helping local Maryland businesses one at a time.&nbsp;
                                                                        <a href='https://marylandisopen.com/' target='_blank' rel='noopener noreferrer'> MarylandIsOpen.com</a>&nbsp;was developed as a resource for local businesses and their consumers.&nbsp;Now that more Maryland stores and businesses are open, we wanted to have deeper conversations with the community.
                                                                          </p>
                                                                <p>Local media personality Meredith Marx takes this journey around the state to interview local business owners and leaders.&nbsp;Maryland is a thriving state and we welcome you to listen to and support the Maryland Is Open community.</p>
                                                                <p>
                                                                    If you or your organization are interested in getting interviewed or want to tell your story, please send us an email at <a href='mailto:mdisopen@enradius.com' target='_blank' rel='noopener noreferrer' >MarylandIsOpen@enradius.com.</a>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <p><span class="font-weight-bold mb-0">Maryland Is Open: Road Runner Specialty Group and Revival Lounge</span><br />Episode 14</p>
                                                        <p>Robin Belle discusses how her company has had to deal with their patient population and working harder in homes. Wellness is so important now in these changing times and Robin has some tips for mental and physical wellness ahead of the cold and flu season. <a href="https://www.revivalounge.com" target="_blank" rel="noopener noreferrer">https://www.revivalounge.com</a></p>
                                                        <p class="mt-3 mb-4"><iframe frameborder="no" preload="none" scrolling="no" width="100%" height="80px"  title="podcast14" src="https://player.audiostaq.com/marylandnewsnet/md_is_open__roa"></iframe></p>

                                                        <p><span class="font-weight-bold mb-0">Maryland Is Open: Target Gov</span><br />Episode 13</p>
                                                        <p>Gloria Larken from Target Gov speaks to Meredith about Federal and Local Government's business unusual during Covid.  Now is a great time for Maryland Businesses to work with the Government. <a href="https://www.targetgov.com" target="_blank" rel="noopener noreferrer">https://www.targetgov.com</a></p>
                                                        <p class="mt-3 mb-4"><iframe frameborder="no" preload="none" scrolling="no" width="100%" height="80px"  title="podcast13" src="https://player.audiostaq.com/marylandnewsnet/md_is_open__tar"></iframe></p>

                                                        <p><span class="font-weight-bold mb-0">Maryland Is Open: The Y of Central Maryland</span><br />Episode 12</p>
                                                        <p>John Hooey speaks about the challenging times at The Y and how they have been handling their business. Providing support to the community is important as well as providing a safe place. Over 160 years old and they will continue to survive and thrive. <a href="https://www.ymaryland.org" target="_blank" rel="noopener noreferrer">https://www.ymaryland.org</a></p>
                                                        <p class="mt-3 mb-4"><iframe frameborder="no" preload="none" scrolling="no" width="100%" height="80px"  title="podcast12" src="https://player.audiostaq.com/marylandnewsnet/md_is_open__the"></iframe></p>

                                                        <p><span class="font-weight-bold mb-0">Maryland Is Open: Vision Planning and Consulting</span><br />Episode 11</p>
                                                        <p>Deepa Srinivasan - President Vision Planning and Consulting discusses how she and her company develop emergency operation plans for cities. How cities and communities can be better prepared for unnatural events. <a href="https://www.visionplanningandconsulting.com" target="_blank" rel="noopener noreferrer">https://www.visionplanningandconsulting.com</a></p>
                                                        <p class="mt-3 mb-4"><iframe frameborder="no" preload="none" scrolling="no" width="100%" height="80px"  title="podcast11" src="https://player.audiostaq.com/marylandnewsnet/md_is_open__vis"></iframe></p>

                                                        <p><span class="font-weight-bold mb-0">Maryland Is Open: Chesapeake Shakespeare Company</span><br />Episode 10</p>
                                                        <p>Gerrad Alex Taylor and Ron speak to Meredith about what's happening to the Theatre Industry and talk about youth and adult acting classes. <a href="https://www.chesapeakeshakespeare.com/education-community/camps-classes/classes/" target="_blank" rel="noopener noreferrer">https://www.chesapeakeshakespeare.com/education-community/camps-classes/classes</a></p>
                                                        <p class="mt-3 mb-4"><iframe frameborder="no" preload="none" scrolling="no" width="100%" height="80px"  title="podcast10" src="https://player.audiostaq.com/marylandnewsnet/chesapeake_shak"></iframe></p>

                                                        <p><span class="font-weight-bold mb-0">Maryland Is Open: Maryland Office of Tourism</span><br />Episode 9</p>
                                                        <p>Liz Fitzsimmons joins Meredith for The Maryland is Open Podcast. Hear Liz and what's happening around the state as we go into fall. Great places to go and see in main street Maryland. Businesses are ready to serve. <a href="https://www.visitmaryland.org" target="_blank" rel="noopener noreferrer">https://www.visitmaryland.org</a></p>
                                                        <p class="mt-3 mb-4"><iframe frameborder="no" preload="none" scrolling="no" width="100%" height="80px" title="podcast9" src="https://player.audiostaq.com/marylandnewsnet/maryland_office"></iframe></p>
                                                        <p><span className='font-weight-bold mb-0'>Maryland Is Open: Goodwill</span><br />Episode 8</p>
                                                        <p>Lisa Rusyniak, President and CEO of Goodwill of The Chesapeake, joins Meredith as she discusses what Goodwill has been doing during Covid times. Learn more <a href='https://www.goodwillches.org/' target='_blank' rel='noopener noreferrer'>https://www.goodwillches.org/</a></p>
                                                        <p className='mt-3 mb-4'><iframe frameborder='no' preload='none' scrolling='no' width='100%' height='80px' title='podcast8' src='https://player.audiostaq.com/marylandnewsnet/maryland_is_ope_4'></iframe></p>

                                                        <p><span className='font-weight-bold mb-0'>Maryland Is Open: Princeton Sports</span><br />Episode 7</p>
                                                        <p>Join Meredith Marx as she interviews Alan Davis from Princeton Sports on how he is protecting customers and his staff. Bikes were deemed essential by the Governor and Princeton was allowed to help the community get around during Covid. Learn more <a href='https://www.princetonsports.com/' target='_blank' rel='noopener noreferrer'>https://www.princetonsports.com/</a></p>
                                                        <p className='mt-3 mb-4'><iframe frameborder='no' preload='none' scrolling='no' width='100%' height='80px' title='podcast7' src=' https://player.audiostaq.com/marylandnewsnet/maryland_is_ope_3'></iframe></p>

                                                        <p><span className='font-weight-bold mb-0'>Maryland Is Open: Catoctin Furnace Historical Society</span><br />Episode 6</p>
                                                        <p>Elizabeth Comer speaks to Meredith about Catoctin Furnace and its Maryland history. The Furnace not only provided nails and horseshoes but it's known for Revolutionary War materials it made to help George Washington defeat the British. Learn about the Spirit of The Furnace and the African American Cemetery and trail. Learn More <a href='https://catoctinfurnace.org/' target='_blank' rel='noopener noreferrer'>https://catoctinfurnace.org/</a></p>
                                                        <p className='mt-3 mb-4'><iframe frameborder='no' preload='none' scrolling='no' width='100%' height='80px' title='podcast6' src='https://player.audiostaq.com/marylandnewsnet/maryland_is_ope_2'></iframe></p>

                                                        <p><span className='font-weight-bold mb-0'>Maryland Is Open: Megan Rich Physical Therapy</span><br />Episode 5</p>
                                                        <p>Meredith Marx speaks to Megan Rich about how Covid affected her business, and being able to do physical therapy and workouts by tele-health and zoom. She discusses how aquatic classes have changed as well as getting PPE for employees and patient safety. Learn more <a href='https://meganrichpt.com/' target='_blank' rel='noopener noreferrer'>https://meganrichpt.com/</a></p>
                                                        <p className='mt-3 mb-4'><iframe frameborder='no' preload='none' scrolling='no' width='100%' height='80px' title='podcast5' src='https://player.audiostaq.com/marylandnewsnet/maryland_is_ope_1'></iframe></p>

                                                        <p><span className='font-weight-bold mb-0'>Maryland is Open: The Baltimore City Chamber</span><br />Episode 4</p>
                                                        <p>Will Holmes from the Baltimore City Chamber discusses how the Chamber had to pivot from doing events to really focusing on and creating strategies for local businesses. From helping with PPP to business plans, the Chamber had to focus on diving deeper into the business community. Learn more <a href='https://baltimorecitychamber.org/' target='_blank' rel='noopener noreferrer'>https://baltimorecitychamber.org/</a></p>
                                                        <p className='mt-3 mb-4'><iframe frameborder='no' preload='none' scrolling='no' width='100%' height='80px' title='podcast4' src='https://player.audiostaq.com/marylandnewsnet/maryland_is_ope'></iframe></p>

                                                        <p><span className='font-weight-bold mb-0'>Maryland Is Open: The Maryland Zoo</span><br />Episode 3</p>
                                                        <p>Meredith speaks with Kirby Fowler at The Maryland Zoo about the Zoo opening in June and how they are practicing social distancing, along with things to come. Learn more <a href='https://www.marylandzoo.org/' target='_blank' rel='noopener noreferrer'>https://www.marylandzoo.org/</a></p>
                                                        <p className='mt-3 mb-4'><iframe frameborder='no' preload='none' scrolling='no' width='100%' height='80px' title='podcast3' src='https://player.audiostaq.com/marylandnewsnet/mdisopen'></iframe></p>

                                                        <p><span className='font-weight-bold mb-0'>Maryland is Open: Maryland Tourism Coalition</span><br />Episode 2</p>
                                                        <p>Meredith Marx chats with Ruth Toomey of the Maryland Tourism Coalition about initiatives the organization is doing to keep tourism moving in the state during COVID.</p>
                                                        <p className='mt-3 mb-4'><iframe src='https://player.audiostaq.com/marylandnewsnet/podcast_171' width='100%' height='80px' title='podcast2' frameborder='no' scrolling='no'></iframe></p>

                                                        <p><span className='font-weight-bold mb-0'>Maryland is Open: The Canton Group</span><br />Episode 1</p>
                                                        <p>In the first episode of the Maryland Is Open Podcast, host Meredith Marx chats with the Canton Group’s Ethan Kazi and Enradius’ Dave Carberry about the concept of showcasing&nbsp; Maryland businesses on <a href='https://www.marylandisopen.com' target='_blank' rel='noopener noreferrer'>MarylandisOpen.com</a></p>
                                                        <p className='mt-3 mb-4'><iframe src='https://player.audiostaq.com/marylandnewsnet/podcast_170' width='100%' height='80px' title='podcast1' frameborder='no' scrolling='no'></iframe></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                    <Footer />
                </div >
            </div >
        )
    }
}

export default podcast


