import React from 'react'

function Sponsors() {
    return (
        <div className='container py-5 text-center'>
            <h3>Our Sponsors</h3>
            <div className='row p-5 align-items-center justify-content-center'>
                <div className='col-sm-4'>
                    <a href='https://cantongroup.com/covid19' target='_blank' rel='noopener noreferrer'>
                        <img src='/img/sponsor-cantongroup.png' alt='sponsor' className='img-fluid my-3' />
                    </a>
                </div>
                <div className='col-sm-4'>
                    <a href='https://enradius.com/mapyourmarketing' target='_blank' rel='noopener noreferrer'>
                        <img src='/img/enradius.png' alt='sponsor' className='img-fluid my-3' />
                    </a>
                </div>
                <div className='col-sm-4'>
                    <a href='https://govreach.net/contact-us' target='_blank' rel='noopener noreferrer'>
                        <img src='/img/sponsor-govreach.png' alt='sponsor' className='img-fluid my-3' />
                    </a>
                </div>
                <div className='col-sm-4'>
                    <a href='https://www.freedomfcu.org/' target='_blank' rel='noopener noreferrer'>
                        <img src='/img/mdioSponseFreedom.png' alt='sponsor' className='img-fluid my-3' />
                    </a>
                </div>
                <div className='col-sm-4'>
                    <a href='https://www.visitfrederick.org/' target='_blank' rel='noopener noreferrer'>
                        <img src='/img/mdioSponserFredrick.png' alt='sponsor' className='img-fluid my-3' />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Sponsors