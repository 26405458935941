import { createStore } from 'redux'

const appReducer = (state = {}, action) => {
    switch (action.type) {
        case 'PLACE':
            return { ...state, place: action.payload };
        case 'ADDRESS':
            return { ...state, address: action.payload };
        default:
            return state;
    }
};

const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (!serializedState) return undefined;
        else return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

const saveState = state => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch (err) {
        console.log(err);
    }
};

const store = createStore(appReducer, loadState());
store.subscribe(() => {
    saveState(store.getState());
});

export default store;