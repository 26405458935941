import React from 'react'

function Header() {
    return (
            <header className='app-header'>
                <a href='/'>
                    <img src='/img/maryland-is-open-logo.png' className='img-header' alt='Maryland Is Open Logo' />
                </a>
                <div className='app-header-bg'>
                </div>
            </header>
    )
}

export default Header