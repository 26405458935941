import React, { Component } from 'react'
import { GoogleApiWrapper } from 'google-maps-react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import Header from '../components/header'
import Footer from '../components/footer'

import Features from '../components/features'
import Sponsors from '../components/sponsors'

import { google_api_key } from '../../config'
import Spinner from '../components/spinner';
import Partners from '../components/partners';
class Home extends Component {
    constructor(props) {
        super(props)

        this.state = {
            categoryId: 0,
            address: '',
            center: { lat: null, lng: null },
            categories: [],
            loading: false,
            route: ''
        }
    }

    componentDidMount() {
        this.setState({ route: '' })
        var isMobile = /iPhone|Android/i.test(navigator.userAgent);
        if (isMobile) {
            this.setState({ route: "mobile-places" })
        } else {
            this.setState({ route: "places" })
        }
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState({ center: { lat: position.coords.latitude, lng: position.coords.longitude }, })
            }, () => {
                this.setState({ center: { lat: 39.2820678, lng: -76.5916704 } })
            });
        }
        else {
            this.setState({ center: { lat: 39.2820678, lng: -76.5916704 } })
        }
    }

    onChange = async (event) => {
        await this.setState({ [event.target.name]: event.target.value });
    }

    handleChange = address => {
        this.setState({ address });
    }

    handleSelect = async value => {
        const address = await geocodeByAddress(value);
        const center = await getLatLng(address[0]);
        await this.setState({ center, address: value });
    };

    search = async () => {
        if (this.state.address !== '') {
            const address = await geocodeByAddress(this.state.address);
            const center = await getLatLng(address[0]);
            this.setState({ center, address: this.state.address || 'undefined' });
        }
        let updatedaddress = this.state.address || 'undefined'
        this.props.history.push(`/${this.state.route}/${parseInt(this.state.categoryId)}/${this.state.center.lat}/${this.state.center.lng}/${updatedaddress}`);
    }

    render() {
        return (
            <div>
                <Header />
                <div className='home-cover'>
                    <div className='container h-100'>
                        <div className='row h-100'>
                            <div className='col-12 m-auto'>
                                <div className='card rounded-0 home-card'>
                                    <div className='card-body'>
                                        <h1 className='home-cover-text'>Your Resource For Finding</h1>
                                        <h1 className='home-cover-text'>Local Maryland Businesses</h1>
                                        <hr className='py-2 ' />
                                        <Spinner display={this.state.loading}>
                                            <div className='form-row align-items-end justify-content-center'>
                                                <div className='col-auto mb-3 d-none d-xl-block'>
                                                    <label className='font-weight-bold home-cover-label'>Show me</label>
                                                </div>
                                                <div className='col-sm mb-3'>
                                                    <label className='font-weight-bold d-xl-none'>Show me</label>
                                                    <select id='categoryId' name='categoryId' className='custom-select home-form rounded-0' value={this.state.categoryId} onChange={this.onChange}>
                                                        <option value='0'>Select a Category</option>
                                                        <option value='1'>Arts and Entertainment</option>
                                                        <option value='2'>Automotive</option>
                                                        <option value='3'>Beauty and Fitness</option>
                                                        <option value='4'>Books and Literature</option>
                                                        <option value='5'>Business Services</option>
                                                        <option value='6'>Computer and Electronics</option>
                                                        <option value='7'>Education</option>
                                                        <option value='15'>Finance and Banking</option>
                                                        <option value='8'>Food and Drinks</option>
                                                        <option value='9'>Healthcare</option>
                                                        <option value='10'>Hobbies and Leisure</option>
                                                        <option value='11'>Home and Garden</option>
                                                        <option value='14'>Hotels and Lodging</option>
                                                        <option value='12'>Pets and Animals</option>
                                                        <option value='13'>Shopping</option>
                                                    </select>
                                                </div>
                                                <div className='col-auto mb-3 ml-2 d-none d-xl-block'>
                                                    <label className='font-weight-bold home-cover-label'>Near me</label>
                                                </div>
                                                <div className='col-sm mb-3'>
                                                    <label className='font-weight-bold d-xl-none'>Near me</label>
                                                    {
                                                        <PlacesAutocomplete value={this.state.address} onChange={this.handleChange} onSelect={this.handleSelect}>
                                                            {
                                                                ({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                                    <div className='search-input-container'>
                                                                        <input {...getInputProps({ placeholder: 'Enter an address or location', className: 'form-control home-form rounded-0' })} value={this.state.address} />
                                                                        <div className='autocomplete-container'>
                                                                            {
                                                                                suggestions.map(suggestion => {
                                                                                    const className = suggestion.active ? 'suggestion-item suggestion-item--active' : 'suggestion-item';
                                                                                    return (
                                                                                        <div {...getSuggestionItemProps(suggestion, { className })}>
                                                                                            <span>{suggestion.description}</span>
                                                                                        </div>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </PlacesAutocomplete>
                                                    }
                                                </div>

                                                <div className='col-sm-auto text-center'>
                                                    <button type='button' className='btn btn-mdio-red mb-3' style={{ fontWeight: 600 }} onClick={() => this.search()}>Go!</button>
                                                </div>
                                            </div>
                                        </Spinner>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Features />

                <Sponsors />
                <Partners />

                <Footer />
            </div>
        )
    }
}

export default GoogleApiWrapper({ apiKey: google_api_key })(Home);