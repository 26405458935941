import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import swal from 'sweetalert';
import Firebase from '../../Firebase'
import { toast } from 'react-toastify';
import { connect } from 'react-redux'

import 'react-toastify/dist/ReactToastify.css';

import { collection_places } from '../../config'

import Spinner from '../../app/components/spinner'
import Header from '../components/header'

toast.configure()
const db = Firebase.firestore();

class Places extends Component {
    constructor(props) {
        super(props)
        this.state = {
            address: '',
            places: [],
            center: { lat: null, lng: null },
            categories: [],
            type_id: 0,
            loading: false,
            files: [],
            upload: false
        }
    }

    componentDidMount() {
        this.getPlaces();
        let token = localStorage.getItem('loginToken');
        if (!token) {
            this.props.history.push('/admin')
        }
    }

    getPlaces = async () => {
        await this.setState({ loading: true });
        db.collection(collection_places).orderBy("name", "asc").onSnapshot(async (dbPlaces) => {
            let places = [];
            dbPlaces.forEach(dbPlace => {
                places.push(dbPlace.data());
            });
            await this.setState({ places, loading: false });
        })
    }

    categoryId = (category) => {
        this.setState({ categoryId: category.Id }, () => {
            this.getPlaces()
        })
    }

    onDeletePlace = (place) => {
        swal({
            title: 'Confirm Delete',
            text: `Are you sure that you want to delete ${place.name}?`,
            icon: 'error',
            buttons: true,
            dangerMode: true,
        }).then(status => {
            if (status) {
                db.collection(collection_places).doc(place.place_id).delete()
                    .then((response) => {
                        toast.info(`${place.name} has been permanently deleted`);
                    })
                    .catch((error) => {
                        console.log(error);
                        toast.error('Oops! There was an error trying to process your request. Please try again or contact support@eventhub.app');
                    });
            }
        });
    }

    editPlace = (place) => {
        this.props.dispatch({ type: 'PLACE', payload: place });
        this.props.history.push('/admin/places/edit');
    }

    render() {
        return (
            <div>
                <Header />
                <div className='container'>
                    <div className='row align-items-center mt-3'>
                        <div className='col'>
                            <h2>Businesses</h2>
                        </div>
                        <div className='col-auto'>
                            <Link to='/admin/places/create' className='btn btn-sm btn-mdio-red-admin'>Add Business</Link>
                        </div>
                    </div>
                    <hr />
                    <div className='table-responsive'>
                        <Spinner display={this.state.loading}>
                            <table className='table table-striped table-bordered table-hover'>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th className='text-center'>Category</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.places.map((place, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <p className='mb-1'>{place.name}</p>
                                                        <p className='small text-muted admin-places'>{place.address}</p>
                                                    </td>
                                                    <td className='text-center'>  {place.category_id === 8 ? 'Food and Drinks' : place.category_id === 9 ? 'Healthcare' : place.category_id === 1 ? 'Art and Entertainment' : place.category_id === 2 ? 'Automotive' : place.category_id === 3 ? 'Beauty and Fitness' : place.category_id === 4 ? 'Books and Literature' : place.category_id === 5 ? 'Business Services' : place.category_id === 6 ? 'Computer and Electronics' : place.category_id === 7 ? 'Education' : place.category_id === 10 ? 'Hobbies and Leisure' : place.category_id === 11 ? 'Home and Garden' : place.category_id === 12 ? 'Pets and Animals' : place.category_id === 13 ? 'Shopping' : place.category_id === 14 ? 'Hotels and Lodging' : place.category_id === 15 ? 'Finance and Banking' : ''}</td>
                                                    <td className='text-center'>
                                                        <button onClick={() => { this.editPlace(place) }} className='btn btn-sm btn-mdio-red-admin'>Edit</button>
                                                        <button onClick={() => { this.onDeletePlace(place) }} className='btn btn-sm btn-mdio-red-admin ml-2'>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </table>
                        </Spinner>
                    </div>
                </div>
            </div >
        )
    }
}


const mapStatetoProps = state => {
    return {
        place: state.place
    };
};

export default connect(mapStatetoProps)(Places);