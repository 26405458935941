import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import home from './app/pages/home.js'
import places from './app/pages/places.js'
import mobileplaces from './app/pages/mobile-places.js'
import addBusiness from './app/pages/addBusiness.js'
import becomeSponsor from './app/pages/becomeSponsor.js'

import adminLogin from './admin/pages/Login.js'
import adminPlaces from './admin/pages/places.js'
import adminPlaceAddEdit from './admin/pages/placeAddEdit.js'

import podcast from './app/pages/podcast'

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      route: ''
    }
  }

  componentDidMount() {
    var isMobile = /iPhone|Android/i.test(navigator.userAgent);
    if (isMobile) {
      this.setState({ route: "mobile-places" })
    } else {
      this.setState({ route: "places" })
    }
  }

  render() {
    return (
      <div>
        <Router>
          <Switch>
            {/* App */}
            <Route exact path='/' component={home} />
            <Route exact path='/add-business' component={addBusiness} />
            <Route exact path='/become-sponsor' component={becomeSponsor} />
            <Route exact path='places' component={places} />
            <Route exact path={`/mobile-places/:category/:lat/:lng/:address`} component={mobileplaces} />
            <Route exact path={`/places/:category/:lat/:lng/:address`} component={places} />

            {/* Admin */}
            <Route exact path='/admin' component={adminLogin} />
            <Route exact path='/admin/places' component={adminPlaces} />
            <Route exact path='/admin/places/create' component={adminPlaceAddEdit} />
            <Route exact path='/admin/places/edit' component={adminPlaceAddEdit} />

            <Route exact path='/podcast' component={podcast} />

          </Switch>
        </Router>
      </div>
    )
  }
}

export default App

